export const TIMEOUT_HTTP_STATUSES = [408, 504];

export const TIMEOUT_ERROR_MESSAGE = 'Request timed out. For best results, adjust the filters to narrow your search.';

function parseString(x: any): string | null {
    if (typeof x === 'string') return x;

    if (Array.isArray(x) && typeof x[0] === 'string') return x.join('. ');

    return null;
}

function parseAndTestString(x: any) {
    const es = parseString(x);
    return es && es.length < 2000 && ['<!DOCTYPE', '<!doctype', '<html'].every(x => !es.startsWith(x) && !es.startsWith(`\n${x}`)) ? es : null;
}

export function extractErrorMessage(
    error: any,
    taskDescription?: string,
    defaultMessage?: string,
    lookForMessage = false,
    additionalLookUpKeys: string[] = [],
    lookUpAllKeys = false
): string {
    let es = error?.error ?
        extractErrorMessage(error.error, taskDescription, defaultMessage, true, additionalLookUpKeys) :
        parseAndTestString(error);
    if (es) return es;

    const lookUpProps = ['detail', 'details', ...additionalLookUpKeys, 'non_field_errors'];
    if (lookForMessage) lookUpProps.push('message');
    if (lookUpAllKeys && typeof error === 'object') lookUpProps.push(...Object.keys(error));

    for (const key of lookUpProps) {
        es = parseAndTestString(error[key]);
        if (es) return es;
    }

    if (TIMEOUT_HTTP_STATUSES.includes(error?.status)) return TIMEOUT_ERROR_MESSAGE;

    if (defaultMessage) return defaultMessage;

    return taskDescription ? `An error occurred while trying to ${taskDescription}` : 'There was an error';
}
